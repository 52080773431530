import { useState, useEffect } from 'react';

import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

import useAuth from '../../hooks/authenticationHooks';

import AccommodationsTable from '../../components/AccommodationsTable';
import AddAccommodation from '../../components/AddAccommodation';

import './OTAPublishing.css';

export default function OTAPublishing() {
	const [filterAccommodations, setFilterAccommodations] = useState([]);
	const [publishedAccommodations, setPublishedAccommodations] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [infoMessage, setInfoMessage] = useState("");

	const { username, userAccessToken } = useAuth()

	let content = "";

	useEffect(() => {
		if (userAccessToken) {
			getAccommodations();
		}
	}, [userAccessToken]);

	async function getAccommodations() {
		setLoading(true);

		await fetch('accommodations/get', {
			method: 'GET',
			cache: 'no-cache',
			headers: {
				'X-Authorization': userAccessToken,
				'X-User': username,
			}
		})
			.then(response => response.json())
			.then(data => {
				setPublishedAccommodations(mapPublishedAccommodations(data.publishedAccommodations));
				setFilterAccommodations(data.filterAccommodations);
				setLoading(false);
				setError(false);
			})
			.catch((error) => {
				console.error('Error:', error);
				setLoading(false);
				setError(true);
			});
	}

	function mapPublishedAccommodations(accommodations) {
		let mappedAccommodations = accommodations.map(acc => ({
			link: acc.link,
			name: acc.name,
			status: acc.status,
			vpVersion: acc.vpVersion,
			channelManagerId: acc.channelManagerId,
			centreName: acc.centreName,
			isPublishingStopped: acc.isPublishingStopped,
			dateModified: new Date(acc.dateModified),
			formattedDate: new Date(acc.dateModified).toLocaleString("en-GB")
		}));

		return mappedAccommodations;
	}

	function showError(message) {
		setErrorMessage(message);
		setInfoMessage("");
	}

	function showInfo(message) {
		setErrorMessage("");
		setInfoMessage(message);
	}

	if (loading) {
		content = <p><em>Loading...</em></p>;
	} else if (error) {
		content = <p>Error. Cannot get the list of accommodations. Please refresh the page and try again.</p>;
	} else {
		content = (
			<>
				<AddAccommodation
					filterAccommodations={filterAccommodations}
					publishedAccommodations={publishedAccommodations}
					getAccommodations={getAccommodations}
					showError={showError}
					showInfo={showInfo}
					userAccessToken={userAccessToken}
					username={username}
				/>

				<AccommodationsTable
					publishedAccommodations={publishedAccommodations}
					getAccommodations={getAccommodations}
					showError={showError}
					showInfo={showInfo}
					userAccessToken={userAccessToken}
					username={username}
				/>
			</>
		);
	}

	return (
		<div>
			<h1 className="mb-3">OTA Publishing</h1>
			{errorMessage &&
				<div className="alert alert-danger w-100" role="alert">
					{errorMessage}
				</div>
			}
			{infoMessage &&
				<div className="alert alert-success w-100" role="alert">
					{infoMessage}
				</div>
			}
			<AuthenticatedTemplate interactionType={InteractionType.Redirect}>
				{content}
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<p>You need to sign in to see this page. Please wait for a redirect to the sign in page.</p>
			</UnauthenticatedTemplate>
		</div>
	);
}