import React, { Component } from 'react';
import SelectSearch from 'react-select-search';
import './SelectSearch.css';
import fuzzySearch from '../helpers/fuzzySearch';

class AddAccommodation extends Component {
	static displayName = AddAccommodation.name;

	constructor(props) {
		super(props);

		this.state = {
			options: null,
			selectedResource: "",
			disabled: false
		}

		this.setDropdownOptions = this.setDropdownOptions.bind(this);
		this.publishSelectedAccommodation = this.publishSelectedAccommodation.bind(this);
	}

	async componentDidMount() {
		this.setDropdownOptions();
	}

	setDropdownOptions() {
		const filterAccommodations = this.props.filterAccommodations;
		const publishedAccommodations = this.props.publishedAccommodations;

		let dropdownOptions = filterAccommodations ? filterAccommodations.map(accommodation => ({
			name: accommodation.name + (accommodation.name && accommodation.centreName ? ' (' + accommodation.centreName + ')' : ''),
			value: accommodation.link
		})).filter(option =>
			option.name &&
			option.value &&
			!publishedAccommodations.some(p => p.link === option.value)
		).sort((a, b) => {
			if (a.name < b.name) {
				return -1;
			}
			if (a.name > b.name) {
				return 1;
			}
			return 0;
		}) : [];

		this.setState({
			options: dropdownOptions
		});
	}

	async publishSelectedAccommodation() {
		const resource = this.state.selectedResource;
		if (!resource) {
			this.props.showError("Please select a villa to publish from the list.");
			return;
		}

		if (this.state.disabled)
			return;

		this.setState({ disabled: true });

		await fetch('accommodations/publish', {
			method: 'POST',
			cache: 'no-cache',
			headers: {
				'X-Authorization': this.props.userAccessToken,
				'X-User': this.props.username,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(resource)
		})
			.then(response => response.json())
			.then(success => {
				if (success === true) {
					this.props.showInfo("Accommodation published successfully.");
					this.props.getAccommodations();
				}
			})
			.catch((error) => {
				this.props.showError(error);
			});
	}

	render() {
		const { options } = this.state;
		if (!options) {
			return null;
		} else {
			return (
				<div className="add-accommodation-wrap mb-5">
					<h5>Publish a new accommodation</h5>
					<div className="add-accommodation-select d-flex justify-content-between align-items-center">
						<SelectSearch
							options={options}
							value={this.state.selectedResource}
							placeholder="Search for a villa"
							emptyMessage="No matching results"
							search
							onChange={(value) => this.setState({
								selectedResource: value
							})}
							filterOptions={(options) => {
								const filter = fuzzySearch(options);
								return (q) => filter(q).slice(0, 20);
							}}
						/>
						<button disabled={this.state.disabled} className="btn btn-success ms-3"
							onClick={() => this.publishSelectedAccommodation()}>
							{this.state.disabled ? 'Publishing...' : 'Publish'}
						</button>
					</div>
				</div>
			);
		}
	}
}

export default AddAccommodation;